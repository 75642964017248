@import '@egjs/react-flicking/dist/flicking.css';
@import '@egjs/flicking-plugins/dist/pagination.css';
@import '@mdxeditor/editor/style.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;

    --ring: 240 5.9% 10%;
    --radius: 0.5rem;
    --font-sans: 'Inter';
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;

    --ring: 240 4.9% 83.9%;
    --radius: 0.5rem;
    --font-sans: 'Inter';
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
    overflow-x: hidden;
  }
}
/* Transitions for the pagination */
.flicking-pagination-bullet {
  transition: border-radius 0.5s, background-color 0.5s, height 0.5s;
  transition-delay: 0.5s, 0.5s, 0s;
}

.flicking-pagination-bullet.flicking-pagination-bullet-active {
  background-color: #8d8d8d;
  border-radius: 8px;
  height: 24px;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.mdxeditor-toolbar {
  margin-bottom: 6px;
}

.report-mdx {
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
}

/* Custom double shadow by C&B */
.shadow-double {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.1);
}

.keyTopicsList {
  list-style-type: disc;
  padding-bottom: 48px;
  @apply pl-4;
}

.keyTopicsList li {
  padding-top: 16px;
  @apply font-bold;
  @apply text-base;
}
.keyTopicsList dl {
  list-style-type: none;
  padding-top: 8px;
}
.keyTopicsLegend dl {
  list-style-type: none;
  padding-bottom: 48px;
  padding-top: 0px;
  @apply text-sm;
  @apply font-normal;
}
.keyTopicsList dl li {
  display: flex;
  @apply text-sm;
  @apply font-normal;
}
.keyTopicsList dl li:before,
.keyTopicsLegend dl li:before {
  content: ' ';
  display: inline-block;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  @apply rounded-full;
  @apply mx-4;
  @apply mt-1;
}
.keyTopicsList dl li.blue:before,
.keyTopicsLegend dl li.blue:before {
  @apply bg-blue-500;
}
.keyTopicsList dl li.sky:before,
.keyTopicsLegend dl li.sky:before {
  @apply bg-sky-500;
}
.keyTopicsList dl li.red:before,
.keyTopicsLegend dl li.red:before {
  @apply bg-red-500;
}
